<section class="Dialog " *ngIf="visible">
	<div class="fixed inset-0 flex items-center justify-center z-50">

		<div class="absolute inset-0 bg-darkBlue opacity-50"></div> <!-- Overlay -->
		<div class="dialog-container min-w-[220px] max-w-[90]  relative bg-semiLightGray p-6 rounded-lg shadow-lg ">
			<!-- Dialog box -->
			<!-- Dialog content goes here -->
			<div class="flex flex-row justify-start cancle">
				<svg (click)="onCancelUploadDialog()" class="cursor-pointer  ahmed" width="22" height="22"
					viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 1L21 21M21 1L1 21" stroke="#f9c728" stroke-width="1.5" stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>
			</div>

			<div *ngIf="!isVideo" class="mb-10 mt-5 flex flex-row items-center justify-center ">
				<button (click)="selectFile()" *ngIf="!selectedImage && !isEdit"
					class="group flex flex-col space-y-4 items-center justify-center max-w-full w-full h-40 rounded-lg border-2 border-dashed border-yellow-500 text-fontColor hover:text-yellow-500 hover:shadow-2xl">
					<svg xmlns="http://www.w3.org/2000/svg" width="64.5" height="64.5" viewBox="0 0 64.5 64.5">
						<g id="Group_41173" data-name="Group 41173" transform="translate(-687.75 -218.5)">
							<path id="Path_33301" data-name="Path 33301"
								d="M62,22.5V35.278a6.389,6.389,0,0,1-6.389,6.389H10.889A6.389,6.389,0,0,1,4.5,35.278V22.5"
								transform="translate(686.75 237.833)" fill="none" stroke="currentColor"
								stroke-linecap="round" stroke-linejoin="round" stroke-width="7" />
							<path id="Path_33302" data-name="Path 33302" d="M42.444,20.472,26.472,4.5,10.5,20.472"
								transform="translate(693.528 217.5)" fill="none" stroke="currentColor"
								stroke-linecap="round" stroke-linejoin="round" stroke-width="7" />
							<path id="Path_33303" data-name="Path 33303" d="M18,4.5V42.833"
								transform="translate(702 217.5)" fill="none" stroke="currentColor"
								stroke-linecap="round" stroke-linejoin="round" stroke-width="7" />
						</g>
					</svg>
					@if(isPhotos||isNews){
					<div class="text-gray-500 helper">
						{{"helperText.image"|translate}}
					</div>
					}
					@else {
						<div 
						class= 'text-gray-500 helper '>
						{{"helperText.video" | translate}}
					  </div>
					}

				</button>
				<div *ngIf="selectedImage && !isEdit" (click)="selectFile()"
					class="p-3 flex items-center justify-center max-w-full w-full h-40 rounded-lg border-2 border-dashed border-yellow-500">
					<img [src]="selectedImage" alt="Selected Image" class="w-full h-full object-contain">
				</div>
				<div *ngIf="isEdit && !selectedImage" (click)="selectFile()"
					class="p-3 flex items-center justify-center max-w-full w-full h-40 rounded-lg border-2 border-dashed border-yellow-500">
					<img [src]="news!.image_url" alt="Selected Image" class="w-full h-full object-contain">
				</div>
				<div *ngIf="selectedImage&&isEdit" (click)="selectFile()"
					class="p-3 flex items-center justify-center max-w-full w-full h-40 rounded-lg border-2 border-dashed border-yellow-500">
					<img [src]="selectedImage" alt="Selected Image" class="w-full h-full object-contain">
				</div>
				<!-- ahmed omar -->
				<input type="file" #fileInput style="display: none" (change)="handleFile($event)"
					[accept]="ACCEPTED_IMAGE_EXTENSIONS">

			</div>

			<ng-container *ngIf="isNews">

				<div>
					<p class="text-base font-semibold text-fontColor my-4">
						{{(isEdit?"account.account_management.news_Media.Edit"
						:"account.account_management.news_Media.Add") | translate}} {{
						"account.account_management.player_Info.description_new_ar" | translate }}</p>
					<textarea [(ngModel)]="news!.content_ar" style="resize: none; "
						class="h-20 w-full rounded  px-6 pt-2 border-none focus:outline-none focus:shadow-none	"
						placeholder="{{ 'account.account_management.My_Booking.Type_Here' | translate }}"
						name="textAreaValue" id="" #textAreaValue="ngModel"
						(input)="checkInputValidity(textAreaValue, [{ type: 'required', value: null }, { type: 'minlength', value: 3 }, { type: 'maxlength', value: 500 }])"
						cols="30" rows="10" appArabicOnly></textarea>
					<div class="text-red-500"
						*ngIf="textAreaValue.invalid && (textAreaValue.dirty || textAreaValue.touched)">
						<p *ngIf="textAreaValue.errors?.['required']" class="text-red-500">
							{{'ac_info_errors.desc_req'|translate}}</p>
						<p *ngIf="textAreaValue.errors?.['minlength']" class="text-red-500">
							{{'ac_info_errors.desc_min'|translate}}</p>
						<p *ngIf="textAreaValue.errors?.['maxlength']" class="text-red-500">
							{{'ac_info_errors.desc_max'|translate}}</p>
					</div>
				</div>

				<div>
					<p class="text-base font-semibold text-fontColor my-4">
						{{(isEdit?"account.account_management.news_Media.Edit"
						:"account.account_management.news_Media.Add") | translate}} {{
						"account.account_management.player_Info.description_new_en" | translate }}</p>
					<textarea [(ngModel)]="news!.content_en" style="resize: none; " minlength="3" maxlength="500"
						class="h-20 w-full rounded px-6 pt-2 border-none focus:outline-none focus:shadow-none	"
						placeholder="{{ 'account.account_management.My_Booking.Type_Here' | translate }}"
						name="contenten" id="" cols="30" rows="10" #contenten="ngModel"
						(input)="checkInputValidity(contenten, [{ type: 'required', value: null }, { type: 'minlength', value: 3 }, { type: 'maxlength', value: 500 }])"
						appEnglishOnly>
					</textarea>
					<div class="text-red-500" *ngIf="contenten.invalid && (contenten.dirty || contenten.touched)">
						<p *ngIf="contenten.errors?.['required']" class="text-red-500">
							{{'ac_info_errors.desc_req'|translate}}</p>
						<p *ngIf="contenten.errors?.['minlength']" class="text-red-500">
							{{'ac_info_errors.desc_min'|translate}}</p>
						<p *ngIf="contenten.errors?.['maxlength']" class="text-red-500">
							{{'ac_info_errors.desc_max'|translate}}</p>
					</div>

				</div>

			</ng-container>
			<div *ngIf="isVideo" class="mb-10 mt-5 flex flex-row items-center justify-center">
				<!-- Conditional box for upload button -->
				<button (click)="selectFile()" *ngIf="!videoSrc && !isEdit && !file" class="">
					<!-- Your upload button SVG -->
					<svg xmlns="http://www.w3.org/2000/svg" width="64.5" height="64.5" viewBox="0 0 64.5 64.5">
						<g id="Group_41173" data-name="Group 41173" transform="translate(-687.75 -218.5)">
							<path id="Path_33301" data-name="Path 33301"
								d="M62,22.5V35.278a6.389,6.389,0,0,1-6.389,6.389H10.889A6.389,6.389,0,0,1,4.5,35.278V22.5"
								transform="translate(686.75 237.833)" fill="none" stroke="currentColor"
								stroke-linecap="round" stroke-linejoin="round" stroke-width="7" />
							<path id="Path_33302" data-name="Path 33302" d="M42.444,20.472,26.472,4.5,10.5,20.472"
								transform="translate(693.528 217.5)" fill="none" stroke="currentColor"
								stroke-linecap="round" stroke-linejoin="round" stroke-width="7" />
							<path id="Path_33303" data-name="Path 33303" d="M18,4.5V42.833"
								transform="translate(702 217.5)" fill="none" stroke="currentColor"
								stroke-linecap="round" stroke-linejoin="round" stroke-width="7" />
						</g>
					</svg>
				</button>
				<!-- Video preview, shown only if there is a video source -->
				<div *ngIf="videoSrc" class="file-description flex items-center justify-evenly">
					<div class="file-description-type">
						<svg focusable="false" aria-hidden="true" class="w-[6rem] ">
							<use [attr.xlink:href]="'#icon-video'"></use>
						</svg>
					</div>
					<div class="file-description-data flex flex-col overflow-hidden">
						<div>
							<div class="text-fontColor">
								{{'file.name'|translate}}
							</div>
							<strong class="text-lightBlue font">
								{{file?.name}}
							</strong>
						</div>
						<div>
							<div class="text-fontColor">
								{{'file.size'|translate}}
							</div>
							<strong class="text-lightBlue font">
								{{file?.size |fileSize}}
							</strong>
						</div>

					</div>
					<!-- <video controls>
						<source [src]="videoSrc" type="video/mp4">
						Your browser does not support the video tag.
					</video> -->
				</div>
				<!-- Optional spinner or loading indicator for upload progress -->
				<div *ngIf="!videoSrc && !isEdit && file" (click)="selectFile()"
					class="p-3 flex items-center justify-center max-w-full w-full h-40 rounded-lg border-2 border-dashed border-yellow-500">
				</div>
				<input type="file" #fileInput style="display: none" (change)="handleFile($event)"
					accept=".mp4,.mpeg,.avi">
			</div>
			@if (isVideo) {
				<div 
				[ngClass]="(videos.status ) ? 'disapeareTexthelper' : 'text-gray-500 helper'">
				{{"helperText.video" | translate}}
			  </div>
			}
			<!-- Helper message for video upload max size -->
			<div *ngIf="isVideo" class="text-center text-sm text-gray-500 mt-2">
				{{ 'sizeVideo' | translate }}
			</div>
			@if(isVideo && !uploadedProgress()){
			<form #videoForm="ngForm"><!-- Video title -->
				<div>
					<p class="text-base font-semibold text-fontColor my-4">
						{{(isEdit?"account.account_management.news_Media.Edit"
						:"account.account_management.news_Media.Add") | translate}} {{
						"account.account_management.player_Info.video_title" | translate }}</p>
					<input type="text" [ngModel]="videos.title" (ngModelChange)="titleChange($event)" style="resize: none; " required minlength="3"
						maxlength="200"
						class="h-14 w-full rounded px-6 pt-2 border-none focus:outline-none focus:shadow-none	"
						placeholder="{{ 'account.account_management.My_Booking.Type_Here' | translate }}" name="title"
						id="" #title="ngModel"
						[ngClass]="{ 'border-red-500': title.invalid && (title.dirty || title.touched) }"
						(input)="checkInputValidity(title, [{ type: 'required', value: null }, { type: 'minlength', value: 3 }, { type: 'maxlength', value: 200 }])">
					<div class="text-red-500" *ngIf="title.invalid && (title.dirty || title.touched)">
						<p *ngIf="title.errors?.['required']" class="text-red-500">{{'ac_info_errors.req'|translate}}
						</p>
						<p *ngIf="title.errors?.['minlength']" class="text-red-500">
							{{'ac_info_errors.minfield'|translate}}</p>
						<p *ngIf="title.errors?.['maxlength']" class="text-red-500">
							{{'ac_info_errors.maxfield2'|translate}}</p>
					</div>

				</div>
				<!-- Video description -->
				<div>
					<p class="text-base font-semibold text-fontColor my-4">
						{{(isEdit?"account.account_management.news_Media.Edit"
						:"account.account_management.news_Media.Add") | translate}} {{
						"account.account_management.player_Info.description" | translate }}</p>
					<textarea [ngModel]="videos.description" (ngModelChange)="descriptionChange($event)" style="resize: none; " required minlength="3" maxlength="500"
						class="h-20 w-full rounded px-6 pt-2 border-none focus:outline-none focus:shadow-none	"
						placeholder="{{ 'account.account_management.My_Booking.Type_Here' | translate }}"
						name="description" id="" #description="ngModel" cols="30" rows="10"
						[ngClass]="{ 'border-red-500': description.invalid && (description.dirty || description.touched) }"
						(input)="checkInputValidity(description, [{ type: 'required', value: null }, { type: 'minlength', value: 3 }, { type: 'maxlength', value: 500 }])"></textarea>
					<div class="text-red-500" *ngIf="description.invalid && (description.dirty || description.touched)">
						<p *ngIf="description.errors?.['required']" class="text-red-500">
							{{'ac_info_errors.desc_req'|translate}}</p>
						<p *ngIf="description.errors?.['minlength']" class="text-red-500">
							{{'ac_info_errors.desc_min'|translate}}</p>
						<p *ngIf="description.errors?.['maxlength']" class="text-red-500">
							{{'ac_info_errors.desc_max'|translate}}</p>

					</div>

				</div>

				@if(!uploadedProgress()){
				<!-- @if (!pending) { -->
				<button (click)="editItemPendingAndApprove()" *ngIf="isEdit==true" [disabled]="isSubmitting"
					class="ms-auto mt-4 flex flex-row justify-center items-center w-40 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-lg shadow-md hover:border hover:border-lightBlue hover:text-lightBlue">
					{{ "account.account_management.news_Media.Edit" | translate }}
				</button>

				<button  (click)="addPost()" [disabled]="isUploading" *ngIf="isEdit==false"
					class="ms-auto mt-4 flex flex-row justify-center items-center w-40 h-10 bg-semiLightGray border border-fontColor text-base text-fontColor rounded-lg shadow-md hover:border hover:border-lightBlue hover:text-lightBlue">
					{{ "account.account_management.news_Media.Add" | translate }}
				</button>


				}
			</form>
			}@else {
			<p-progressBar *ngIf="isVideo" [value]="uploadedProgress()" />
			}


		</div>
	</div>
</section>


<svg style="display: none;" xmlns="http://www.w3.org/2000/svg">

	<symbol id="icon-video" viewBox="0 0 32 32">
		<path fill="#2a4157" style="fill: var(--color1, #1072ce)" opacity="0.24"
			d="M17.333 4h-8c-1.473 0-2.667 1.194-2.667 2.667v18.667c0 1.473 1.194 2.667 2.667 2.667h13.333c1.473 0 2.667-1.194 2.667-2.667v-13.333h-4.667c-1.841 0-3.333-1.492-3.333-3.333v-4.667zM25.090 10.667c-0.133-0.289-0.317-0.555-0.547-0.783l-5.161-5.112c-0.211-0.209-0.453-0.379-0.715-0.506v4.401c0 1.105 0.895 2 2 2h4.424z">
		</path>
		<path fill="#222" style="fill: var(--color2, #fdfdfd)"
			d="M13.333 21.489v-5.645c0-0.518 0.565-0.838 1.010-0.572l4.704 2.823c0.432 0.259 0.432 0.884 0 1.143l-4.704 2.823c-0.444 0.267-1.010-0.053-1.010-0.572z">
		</path>
	</symbol>
</svg>