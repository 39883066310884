import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, Input, WritableSignal, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AcademyCard, Sport } from '@sportyano/core/models/academy-card/academy-card.model';
import { profileParams } from '@sportyano/core/models/account/profile';
import { City } from '@sportyano/core/models/cities/cities.model';
import { Countries } from '@sportyano/core/models/countries/countries.model';
import { permission } from '@sportyano/core/models/permissions/permission';
import { Property } from '@sportyano/core/models/sports-human/sports-human.model';
import { AcademyService } from '@sportyano/core/services/academy/academies/academy.service';
import { GeneralService } from '@sportyano/core/services/generals/general.service';

interface Filter {
	sort: SortOptions,
	search: string,
	SearchByCity: string,
	SearchByCountry: string,
	selectConutry: number[],
	selectSports: string,
	selectAcademy: string,
	contract?: boolean,
	filterSport?: string,
	selectPosition?: string,
	position: string;
	age_from: number|undefined;
	age_to: number|undefined;
	nationalities?: number[];
}

interface SortOptions {
	name: string, id: number, isAsc: boolean, corrctName?: string
}

@Component({
	selector: "app-advanced-side-filteration",
	templateUrl: "./advanced-side-filteration.component.html",
	styleUrls: ["./advanced-side-filteration.component.scss"],
})
export class AdvancedSideFilterationComponent implements OnInit {
	showTooltip = false;
	@Input() filterType: string;
	@Output() filterOption = new EventEmitter<any>();
	@Output() resetFilter = new EventEmitter<void>();
	public profileParam: WritableSignal<any> = signal<any>({});
	public sports: WritableSignal<Sport[]> = signal<Sport[]>([]);
	readonly minimumAge: number = 1980;
	readonly maximumAge: number = 2020;
	chosenMinimumAge: number = 1960;
	chosenMaximumAge: number = new Date().getFullYear()-2;
	ageRange: number[] = [ this.chosenMaximumAge,this.chosenMinimumAge];
	permission = permission;
	countries: Countries[] = [];
	cities: City[] = [];
	// sports: Sport[] = [];

	academies: AcademyCard[] = [];
	filterSport: Property[] = [];
	positions: any[] =[]
	egyptId: number | undefined;
	sorting: SortOptions[] = [
		{ id: 1, name: this.translate.instant('date'), isAsc: true, corrctName: 'id' },
		{ id: 2, name: this.translate.instant('name'), isAsc: true, corrctName: 'name' }
	];

	filter: Filter = {
		sort: { name: '', id: 0, isAsc: true, corrctName: '' },
		search: '',
		SearchByCity: '',
		SearchByCountry: '',
		selectConutry: [],
		selectSports: '',
		selectAcademy: '',
		contract: false,
		filterSport: '',
		selectPosition: '',
		position: '',
		age_from: undefined,
		age_to: undefined
	};

	constructor(private generalService: GeneralService, private academyService: AcademyService, private translate: TranslateService, private _activatedRoute: ActivatedRoute) { }

	ngOnInit(): void {
		this.sorting = [
			{ id: 1, name: this.translate.instant('date'), isAsc: true, corrctName: 'id' },
			{ id: 2, name: this.translate.instant('name'), isAsc: true, corrctName: 'name' }
		];
		this.fetchCountries();
		this._listenQueryParams()
		this.fetchSports();

	}
	onAgeChange(event: any): void {
		// Update filter with new age range
		this.filter.age_from = this.ageRange[0];
		this.filter.age_to = this.ageRange[1];
		this.sendFilter();
	}
	private _listenQueryParams() {
		this._activatedRoute.queryParams.subscribe({
			next: (params) => {
				// console.log(params);
				this.profileParam.set(params);
				// console.log(this.profileParam()['profile'],'profileParam');

			}
		})
	}
	toggleSort(item: SortOptions) {
		item.isAsc = !item.isAsc;
		this.filter.sort = item;
		this.sendFilter();
	}
	selectedCountryName: string | undefined;
	private fetchCountries() {
		this.generalService.getCountries().subscribe((data) => {
		  if (data) {
			this.countries = data.records.sort((a, b) => a.name.localeCompare(b.name));
			this.getCity(''); 
			  this.sendFilter(); 
	  
			// this.egyptId = this.countries.find(ele => ele.code === '20')?.id;
			// if (this.egyptId) {
			//   this.filter.SearchByCountry = this.egyptId.toString();  
			//   this.selectedCountryName = this.countries.find(country => country.id === this.egyptId)?.name; 
			//   this.getCity(''); 
			//   this.sendFilter(); 
		// }

		  }
		});
	  }
	 
	  
	

	// private fetchSports() {
	// 	this.generalService.getSports().subscribe((data) => 

	// 		this.sports = data.records.sort((a,b)=>a.name.localeCompare(b.name)));
	// 		console.log(this.sports)
	// }

	private fetchSports() {
		switch (this.profileParam()['profile']) {
			case profileParams.players:
				this.generalService.sportsOnlyUsed(profileParams.players).subscribe({
					next: (data: any) => {
						console.log(data.sports, 'player');

						this.sports.set(data.sports.sort((a: any, b: any) => a.name.localeCompare(b.name)));
					}
				})
				break;
			case profileParams.academies:
				this.generalService.sportsOnlyUsed(profileParams.academies).subscribe({
					next: (data: any) => {
						console.log(data.sports, 'academy');

						this.sports.set(data.sports.sort((a: any, b: any) => a.name.localeCompare(b.name)));
					}
				})
				break;
			case profileParams.playgrounds:
				this.generalService.sportsOnlyUsed(profileParams.playgrounds).subscribe({
					next: (data: any) => {
						console.log(data.sports, 'playground');

						this.sports.set(data.sports.sort((a: any, b: any) => a.name.localeCompare(b.name)));
					}
				})
				break;
				default :
				this.generalService.getSports().subscribe({
					next:(data:any)=>{
						console.log(data.records, 'videos');

						this.sports.set(data.records.sort((a: any, b: any) => a.name.localeCompare(b.name)));
					}
				})
		}
	}

	getSportFilter(event: any): void {
		if (this.filterType === permission.player) {
			this.generalService.getSportsHumen(event.value).subscribe((data) => {
				this.filterSport = data.records.find((ele) => ele.label === 'position')?.properties || [];
			});
		}
		this.fetchPositions(event.value);
	}

	private fetchPositions(sportId: number): void {
		this.generalService.getSportsPositions(sportId).subscribe((data) => {
			this.positions = data.sort((a:any,b:any)=>a.name.localeCompare(b.name))
			// console.log('postions',this.positions)
		});
	}

	getCity(event: any): void {
		this.generalService.getCities(event?.value || this.egyptId).subscribe((data) => {
			this.cities = data.records.sort((a: any, b) => a.name.localeCompare(b.name))
		});
	}

	private fetchDataAcademy(): void {
		console.log(this.filter.selectAcademy);

		this.generalService.getAcademyListwithParam(this.filter).subscribe((data) => {
			// window.alert(this.filter.selectAcademy)
			this.academies = data.data;
			// console.log(this.academies);

		});
	}

	sendFilter(): void {
		const filterObj: any = {
			search: this.filter.search || '',
			sort_by: this.filter.sort.corrctName,
			sort_direction: this.filter.sort.isAsc ? 'asc' : 'desc',
			contract: this.filter.contract,
			nationalities: this.filter.selectConutry || [],
			country_id: this.filter.SearchByCountry || 0,
			city_id: this.filter.SearchByCity || 0,
			sport_id: this.filter.selectSports || 0,
			academy_id: this.filter.selectAcademy || 0,
			property_id: this.filter.filterSport || 0,
			age_from: this.filter.age_from,
			age_to: this.filter.age_to
		};
		console.log('Filter Object:', filterObj);
		console.log('Selected Academy ID:', this.filter.selectAcademy);

		// Add the position filter parameter conditionally
		if (this.filter.selectPosition) {
			filterObj.sport_option_position_id = this.filter.selectPosition;
		}

		this.filterOption.emit(filterObj);
	}



	reset(): void {
		this.filter = {
			sort: { name: '', id: 0, isAsc: true, corrctName: '' },
			search: '',
			SearchByCity: '',
			SearchByCountry: '',
			selectConutry: [],
			selectSports: '',
			selectAcademy: '',
			contract: false,
			filterSport: '',
			selectPosition: '',
			position: '',
			age_from: undefined,
			age_to: undefined
		};
		
		this.ageRange = [this.chosenMaximumAge,this.chosenMinimumAge];
		this.resetFilter.emit();
	}
}
