<div class="video p-2 rounded-lg overflow-hidden relative group">
	<cart StopClickPropagation [image]="mediaVideo?.thumbnail ?? data.thumbnail"
		[title]="mediaVideo?.title ?? data.title" (click)="onPlayVideo()" class="relative video-cart"
		[axis]="'vertical'" [type]="'video'">
		<div imageOverlay class="overlay w-full h-full absolute top-0 left-0 flex justify-center items-center">
			<img class="w-1/3 h-1/2" src="assets/icons/play-button-o.svg" alt="" />
		</div>
		<span postContent>
			<!-- videoDuration -->
			<!-- <ng-container *ngIf="cartComponent">
				<ng-container
					*ngTemplateOutlet="
						cartComponent?.itemDataTemplate!;
						context: {
							primeIcon: 'pi-clock',
							value: videoDuration
						}
					"
				></ng-container>
			</ng-container> -->

			<!-- <p
				TextLimiter
				[maxChars]="50"
				[seeMoreText]="'text.seeMore' | translate"
				class="desc break-large-text mt-3 text-fontColor text-sm word-break-word max-height-one"
			>
				{{ mediaVideo?.description ?? data.description }}
			</p> -->

			<div *ngIf="canDelete" (click)="onEvent($event)"
				class="overlay z-10 w-full h-full flex justify-center items-center transition-opacity hover:opacity-100">
				<button *ngIf="data.status!==0" (click)="deleteUserVideo(data.id)"
					class="absolute top-2 right-2 z-10 rounded-full p-2 outline-none bg-primaryRed text-fontColor hover:bg-primaryRed transition-all">
					<i class="pi pi-trash"></i>
				</button>
				<button *ngIf="data.status!==0 " (click)="openEditVideo(data)"
					class="absolute top-2 right-12 z-10 rounded-full p-2 outline-none bg-green-500 text-fontColor hover:bg-green-500 transition-all">
					<i class="pi pi-user-edit"></i>
				</button>
			</div>

			<div class="fixed inset-0 flex items-center justify-center z-50 pt-6 pr-6 pl-6" *ngIf="visible">
				<div class="absolute inset-0 bg-darkBlue opacity-50"></div>
				<div class="relative bg-semiLightGray p-6 rounded-lg shadow-lg" style="
						width: 600px !important;
						min-height: 450px !important;
					">
					<div class="flex mb-4 cancle" dir="rtl">
						<svg StopClickPropagation (click)="onVideoCartToggle()" class="cursor-pointer" width="22"
							height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L21 21M21 1L1 21" stroke="#f9c728" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</div>

					@if (mediaVideo?.title){
					<h3 class="title leading-4 text-center my-3 text-white">
						{{ mediaVideo?.title }}
					</h3>

					} @else{

					<h3 class="title leading-4 text-center my-3 text-white">
						{{ data?.title }}
					</h3>
					}

					<div
						class="photo-container p-1 rounded-lg overflow-hidden bg-black flex justify-between items-center gap-6">
						<ng-container *ngIf="
								currentLanguage == 'en';
								else reverseArrowLeft
							">
							<div class="left cursor-pointer">
								<!-- <ng-container *ngTemplateOutlet="arrowLeft"></ng-container> -->
							</div>
						</ng-container>
						<ng-template #reverseArrowLeft>
							<div class="right cursor-pointer">
								<!-- <ng-container *ngTemplateOutlet="arrowRight"></ng-container> -->
							</div>
						</ng-template>
						<div class="photo w-full rounded-lg overflow-hidden">
							@if (mediaVideo?.url || data.url) {

							<video #cartVideo class="w-full h-full object-cover"
								[src]="mediaVideo?.url ?? data.url" controls alt="">
								<source [src]="mediaVideo?.url || data.url" />
							</video>
							}
						</div>
						<!-- <ng-container *ngIf="currentLanguage=='ar';else reverseArrowRight"> -->
						<ng-container *ngIf="currentLanguage == 'ar'">
							<div class="left cursor-pointer">
								<!-- <ng-container *ngTemplateOutlet="arrowLeft"></ng-container> -->
							</div>
						</ng-container>
						<ng-template #reverseArrowRight>
							<div class="right cursor-pointer">
								<!-- <ng-container *ngTemplateOutlet="arrowRight"></ng-container> -->
							</div>
						</ng-template>
					</div>

					<div class="video-description" style="
							word-wrap: break-word;
							word-break: break-all;
							white-space: pre-wrap;
						">
						<small class="flex m-auto py-2 text-semiDarkGrey">{{
							"account.account_management.player_Info.description"
							| translate
							}}</small>
						@if (mediaVideo?.description) {
						<p class="title leading-5 text-white my-3" dir="auto">
							{{ mediaVideo?.description }}
						</p>

						} @else{

						<p class="title leading-5 text-center text-white my-3">
							{{ data?.description }}
						</p>
						}
					</div>
					<div style="display: flex; justify-content: center">
						@if (mediaVideo?.slug) {
						<button (click)="navigateToProfile(mediaVideo.slug!)" style="
								color: #fff;
								border: 2px solid rgb(3, 90, 3);
								padding: 10px 20px;
							">
							Go To Player Profile
						</button>
						}
					</div>

					@if(canDelete){
						<div
						class="vide-actions bg-semiLightGray rounded-lg p-2 flex flex-col justify-center items-center"
						*ngIf="data.status==2"
					  >
						<div
						  *ngIf="data.decline_reason"
						  class="p-1 my-1 text-base text-primaryRed bg-darkBlue rounded-xl border border-red-500 shadow-lg flex items-center justify-center h-auto max-w-2xl mx-auto transition-all duration-300 ease-in-out transform hover:scale-105 dark:bg-orange-600 dark:text-orange-100 dark:border-orange-500 font-[Cairo] border-animation"
						  role="alert"
						>
						  <span class="block text-base text-center leading-relaxed text-animation">
							{{ "decline_reason_video" | translate }}
							<strong>{{ data.decline_reason }}</strong>
						  </span>
						</div>
					  
						<button
						  (click)="deleteUserVideo(data.id)"
						  class="z-10 mt-3 rounded-lg p-2 text-primaryRed bg-darkBlue border-primaryRed border text-fontColor hover:bg-primaryRed hover:text-white transition-all"
						>
						  <i class="pi pi-trash text-white mx-1" style="font-size: 0.8rem"></i>
						  <small>
							{{
							  "account.account_management.news_Media.deleteVideo" | translate
							}}
						  </small>
						</button>
					  </div>
					  
					}
				</div>
			</div>
		</span>
	</cart>

	<app-share-modal [visible]="showShare" (OnHide)="showShare = $event"></app-share-modal>
</div>