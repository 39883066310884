<cart
					class="col-span-12 md:col-span-6"
					[title]="academy.branch_name.length > 15
					? (academy?.branch_name | slice: 0: 15) + '...' 
					: (academy?.branch_name || '') "
					[image]="
						academy.pictures.length > 0
							? academy.pictures[0].url
							: '../../assets/images/image/academy-cover.jpg'
					"
					[route]="'/main/academies/' + academy.slug"
					[type]="'academyandplayground'"
				>
					<div postContent class="flex flex-col gap-3">

						<p
							TextLimiter
							[maxChars]="30"
							[seeMoreText]="'text.seeMore' | translate"
							class="desc min-h-8 max-h-8 leading-normal mt-3 text-fontColor text-sm break-all break-large-text cursor-text md:min-h-10 max-h-10"
						>
							{{ academy?.description  }}
						</p>
						<ul
							class="exercises mt-5 flex flex-wrap justify-start gap-2 list-none text-white"
						>
							<ng-container
								*ngFor="
									let sport of academy?.sports | slice:0:2;
									track: $index
								"
							>
								<li class="chip-item">
									<chip
										colorHex="#f9c728"
										[label]="sport.name"
									></chip>
								</li>
							</ng-container>
							@if(academy.sports.length > 2){
								<li class="chip-item">
									<chip
										colorHex="#f9c728"
										label="..."
									></chip>
								</li>
							}
						</ul>
						<!-- address -->
						<ng-container
							*ngTemplateOutlet="
								cartComponent?.itemDataTemplate!;
								context: {
									primeIcon: 'pi-map-marker',
									value:
										academy?.city_name +
										' , ' +
										academy?.country_name
								}
							"
						></ng-container>
					</div>
				</cart>